import { notification } from 'antd';

const debug = false;

const warning = (msg,err) => {

  let error = err; //'';
  if (err==="Network Error") error = 'Verifica la tua connessione a internet.';

  const args = {
    message: msg,
    description: error,
    duration: 5,
  };

  notification.open(args);
}

export {debug, warning};
