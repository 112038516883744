import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

function $_GET(variable) {

	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return(pair[1])}
	}
	return(false);
}
let cf = $_GET('cf')
let ky = $_GET('key')
let from = $_GET('from')
let email = $_GET('email')
let moodle = $_GET('moodle')
let azione = $_GET('act') ? $_GET('act') : 'signup' // signup/confirm/password/username/link

ReactDOM.render(<App cf={cf} ky={ky} email={email} azione={azione} from={from} moodle={moodle} />, document.getElementById('root'));
